import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import styled from 'styled-components';
import widebody from '../images/art/LFallPromo.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
`;

// Create a Image component that'll render with a default src
const Image_1_Url = require('../images/art/Illustration3.jpg');
const Image_1 = styled.img`
width: 100%;
max-height: 1270px;
`;
Image_1.defaultProps = {
    src: Image_1_Url,
    className: "d-block w-100"
  };

// Create a Image component that'll render with a default src
const Image_2_Url = require('../images/art/Illustration3a.jpg');
const Image_2 = styled.img`
width: 100%;
max-height: 1270px;
`;
Image_2.defaultProps = {
    src: Image_2_Url,
    className: "d-block w-100"
  };

// Create a Image component that'll render with a default src
const Image_3_Url = require('../images/art/Illustration3b.jpg');
const Image_3 = styled.img`
max-height: 1270px;
width: 100%;`;
Image_3.defaultProps = {
    src: Image_3_Url,
    className: "d-block w-100"
  };

  // Create a Image component that'll render with a default src
const Image_4_Url = require('../images/art/Illustration3e.jpg');
const Image_4 = styled.img`
max-height: 1270px;
width: 100%;`;
Image_4.defaultProps = {
    src: Image_4_Url,
    className: "d-block w-100"
  };

export default function ImageLineup() {
    return (
        <Wrapper>
            <Row>
                <Col lg={7} className=" d-flex align-items-center justify-content-center">
                    <Image_1 />
                </Col>     
                <Col lg={5} className=" d-flex align-items-center justify-content-center">
                    <Image_2 />
                </Col>    
            </Row>
            <Row>
            <Col lg={5} className=" d-flex align-items-center justify-content-center">
                    <Image_4 />
                </Col> 
                <Col lg={7} className=" d-flex align-items-center justify-content-center">
                    <Image_3 />
                </Col>     
    
            </Row>
        </Wrapper>
    );
  }

