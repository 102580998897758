import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Parallax } from 'react-scroll-parallax';
import { useParallax } from 'react-scroll-parallax';
import About from './About';
import PortfolioCarousel from './PortfolioCarousel';
import Image from './Image';
import BigImage from './BigImage';
import ImageLineup from './ImageLineup';
import Divider from './Divider';
import NavBar from './NavBar';
import Contact from './Contact';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { Routes,Route } from 'react-router-dom';
import { Document } from 'react-pdf'
import styled from 'styled-components';
const Text = styled.h2`
text-align: center;
`
const SocialLinkWrapper = styled.div`
text-align: center;
`
const SocialLinkIcon = styled.img`
width: 100px;
height: 100px;
`
export default class SocialLinks extends React.Component {
  render(){
    return(
      <div>
        <Text> Contact Me </Text>
        <SocialLinkWrapper>
          <a href='https://www.linkedin.com/in/tubbsii/' target='_'> <SocialLinkIcon src='https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg'></SocialLinkIcon> </a>
          <a href='https://www.instagram.com/tubbsii/' target='_'> <SocialLinkIcon src='https://upload.wikimedia.org/wikipedia/commons/9/95/Instagram_logo_2022.svg'></SocialLinkIcon> </a>
          <a href='https://www.youtube.com/channel/UCudfnPzGlLvNbHgatnlLXiA' target='_'> <SocialLinkIcon src='https://upload.wikimedia.org/wikipedia/commons/a/a0/YouTube_social_red_circle_%282017%29.svg'></SocialLinkIcon> </a>
          <a href='https://mail.google.com/mail/?view=cm&fs=1&to=studiotubbsart@gmail.com' target='_'> <SocialLinkIcon src='https://upload.wikimedia.org/wikipedia/commons/7/7e/Gmail_icon_%282020%29.svg'></SocialLinkIcon> </a>
        </SocialLinkWrapper>
      </div>
     )
  }
}
