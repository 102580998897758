import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import { Route, HashRouter as Router, Routes } from "react-router-dom";
import {Link} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
const NavWrapper = styled.div`
    font-size: 2rem;
`;
// Create a Image component that'll render with a default src
const logoUrl = require('../images/logos/studio_tubbs-07.png')
const Logo = styled.img`
max-height: 100px;
`;
Logo.defaultProps = {
    src: logoUrl,
    className: "img-fluid"
  };
export default function NavBar() {
    return (
        <NavWrapper>
            <Navbar bg="light" expand="lg" fixed="top">
                <Container>
                <Navbar.Brand href="/"><Logo /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                    <Nav.Link href="/"> Home </Nav.Link>
                    <Nav.Link href="/Portfolio"> Portfolio </Nav.Link>
                    <Nav.Link href="/Comms"> Comms </Nav.Link>

                    {/* <Nav.Link href="#about">About</Nav.Link> */}
                    {/* <Nav.Link href="#reel">Reel</Nav.Link>
                    <Nav.Link href="#gallery">Gallery</Nav.Link>
                    <Nav.Link href="#contact">Contact</Nav.Link> */}
                    {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                        Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">
                        Separated link
                        </NavDropdown.Item>
                    </NavDropdown> */}
                    </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>
        </NavWrapper>
    );
  }
