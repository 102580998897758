import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBar from './NavBar';
import styled from 'styled-components';
const Spacer = styled.div`
width: 100%;
height: 8rem;
`;

const url = require('../images/art/COMMSHEET.png');
const CommBanner = styled.img`
margin-left: 3rem;
`;
CommBanner.defaultProps = {
    src: url,
    className: "img-fluid"
  };
export default class Comms extends React.Component {
  render(){
    return(
      <Container>
      <NavBar />
      <Row>
        <Col lg={12}>
        <Spacer />
        <CommBanner />
        </Col>
      </Row>
    </Container>
     )
  }
}
