import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from 'styled-components';
import widebody from '../images/art/LFallPromo.png';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Create a Image component that'll render with a default src
const url = require('../images/art/LFallPromo.png');
const Image = styled.img`
width: 100%;
height: 100%`;
Image.defaultProps = {
    src: url,
  };

// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  color: black;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
`;


// Create a Image component that'll render with a default src
const Carousel_Image_1_Url = require('../images/art/c-Aenfaultportrait2.png');
const Carousel_Image_1 = styled.img`
width: 100%;
min-height: 850px;
max-height: 850px;
`;
Carousel_Image_1.defaultProps = {
    src: Carousel_Image_1_Url,
    className: "d-block w-100"
  };

// Create a Image component that'll render with a default src
const Carousel_Image_2_Url = require('../images/art/REDUX2.png');
const Carousel_Image_2 = styled.img`
width: 100%;
min-height: 850px;
max-height: 850px;
`;
Carousel_Image_2.defaultProps = {
    src: Carousel_Image_2_Url,
    className: "d-block w-100"
  };

// Create a Image component that'll render with a default src
const Carousel_Image_3_Url = require('../images/art/A1krowpromoposter2.png');
const Carousel_Image_3 = styled.img`
min-height: 850px;
max-height: 850px;
width: 100%;`;
Carousel_Image_3.defaultProps = {
    src: Carousel_Image_3_Url,
    className: "d-block w-100"
  };

// Create a Image component that'll render with a default src
const Carousel_Image_4_Url = require('../images/art/5THEPOSTER2.png');
const Carousel_Image_4 = styled.img`
min-height: 850px;
max-height: 850px;
width: 100%;`;
Carousel_Image_4.defaultProps = {
    src: Carousel_Image_4_Url,
    className: "d-block w-100"
  };

// Create a Image component that'll render with a default src
const Carousel_Image_5_Url = require('../images/art/5THEPOSTERofchris2.png');
const Carousel_Image_5 = styled.img`
min-height: 850px;
max-height: 850px;
width: 100%;`;
Carousel_Image_5.defaultProps = {
    src: Carousel_Image_5_Url,
    className: "d-block w-100"
  };

  // Create a Image component that'll render with a default src
const Carousel_Image_6_Url = require('../images/art/kamen.png');
const Carousel_Image_6 = styled.img`
min-height: 850px;
max-height: 850px;
width: 100%;`;
Carousel_Image_6.defaultProps = {
    src: Carousel_Image_6_Url,
    className: "d-block w-100"
  };
export default function PortfolioCarousel() {
    return (
        <Wrapper>
            <Row>
                <Col lg={6} className=" d-flex align-items-center justify-content-center no-padding">
                    {/* <Carousel showArrows={true}>
                        <div>
                            <Carousel_Image_1/>
                            <p className="legend">Legend 1</p>
                        </div>
                        <div>
                            <Carousel_Image_2/>
                            <p className="legend">Legend 2</p>
                        </div>
                        <div>
                            <Carousel_Image_3/>
                            <p className="legend">Legend 3</p>
                        </div>
                    </Carousel> */}

                    <Carousel fade >
                        <Carousel.Item>
                            <Carousel_Image_1 />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Carousel_Image_2/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Carousel_Image_3/> 
                        </Carousel.Item>
                        <Carousel.Item>
                            <Carousel_Image_4/> 
                        </Carousel.Item>
                        <Carousel.Item>
                            <Carousel_Image_5/> 
                        </Carousel.Item>
                        <Carousel.Item>
                            <Carousel_Image_6/> 
                        </Carousel.Item>
                    </Carousel>
                </Col> 
                <Col lg={6} className=" d-flex align-items-center justify-content-center">
                    <Image />
                </Col>         
            </Row>
        </Wrapper>
    );
  }

