import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';
import widebody from '../images/art/Lawrence.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Create a Image component that'll render with a default src
const url = require('../images/art/Lawrence.png');
const Image = styled.img`
min-width: 1000px;
min-height: 700px;`;
Image.defaultProps = {
    src: url,
    className: "img-fluid"
  };

// Create a Title component that'll render an <h1> tag with some styles
const Text = styled.span`
`;
const TextWrapper = styled.div`
  display: flex;
    flex-direction: column-reverse;
    align-content: stretch;
    justify-content: flex-end;
    align-items: center;
`
// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
font-size: 3.5em;
text-align: center;
`;

export default function About() {
    return (
        <Wrapper>
          <Row>
            <Col lg={7} className=" d-flex align-items-center justify-content-center">
            <Image />
            </Col>
            <Col lg={5} className=" d-flex align-items-center justify-content-center">
              <TextWrapper>
                <Typewriter
                options={{
                  strings: ['Animator.', 'Designer.', 'Illustrator.', 'Storyboard Artist.', ],
                  autoStart: true,
                  loop: true,
                }}
              />

                <Text>Lawrence Tubbs II</Text>

              </TextWrapper>

              </Col>
          </Row>

        </Wrapper>
    );
    
  }

