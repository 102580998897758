import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Parallax } from 'react-scroll-parallax';
import { useParallax } from 'react-scroll-parallax';
import About from './About';
import PortfolioCarousel from './PortfolioCarousel';
import Image from './Image';
import BigImage from './BigImage';
import ImageLineup from './ImageLineup';
import Divider from './Divider';
import NavBar from './NavBar';
import Contact from './Contact';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { Routes,Route } from 'react-router-dom';
import { Document } from 'react-pdf'
export default class Portfolio extends React.Component {
  render(){
    const iframe = '<iframe style="position:absolute;border:none;width:100%;height:100%;left:0;top:0;" src="https://online.fliphtml5.com/jctqo/vgmw/"  seamless="seamless" scrolling="no" frameborder="0" allowtransparency="true" allowfullscreen="true"></iframe>';
    return(
      <Container>
      <NavBar />
      <Row>
        <Col lg={12}>
          <div>
            <div dangerouslySetInnerHTML={ {__html: iframe}} />
          </div>
        </Col>
      </Row>
    </Container>
     )
  }
}
