import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

// Create a Image component that'll render with a default src
const Url = require('../images/art/ZerseVsUndead.png')
const Image = styled.img`
height: 100%;
width: 100%;
`;
Image.defaultProps = {
    src: Url,
    className: "img-fluid"
  };
export default function NavBar() {
    return (
        <Row>
            <Col lg={12} className="no-padding">
                <Image />
            </Col>
        </Row>
    );
}