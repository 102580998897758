import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

const Wrapper = styled.div`
    padding: 1rem;
`
const Text = styled.h1`
`
export default function Divider(props) {
    return (
        <Wrapper id={props.anchor} className=" d-flex align-items-center justify-content-center">
            <Text>
                {props.header}
            </Text>
        </Wrapper>

    )

}