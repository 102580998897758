import './app.css';
import Home from './components/Home';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Comms from './components/Comms';
import { Routes,Route } from 'react-router-dom';
function App() {
return (
  <Routes>
    <Route exact = {true} path = "/" element={<Home />} />
    <Route path = "/about" element={<About />} />
    <Route path = "/portfolio" element={<Portfolio />} />
    <Route path = "/comms" element={<Comms />} />
  </Routes>
  );
} 
export default App;
