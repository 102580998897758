import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Parallax } from 'react-scroll-parallax';
import { useParallax } from 'react-scroll-parallax';
import About from './About';
import PortfolioCarousel from './PortfolioCarousel';
import Image from './Image';
import BigImage from './BigImage';
import ImageLineup from './ImageLineup';
import Divider from './Divider';
import NavBar from './NavBar';
import Contact from './Contact';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { Routes,Route } from 'react-router-dom';
import SocialLinks from './SocialLinks';
import styled from 'styled-components';

// Create a Image component that'll render with a default src
const url = require('../images/art/COMMISSION_AD_BAN.png');
const Spacer = styled.div`
width: 100%;
height: 8rem;
`;
const CommBanner = styled.img`
min-width: 500px;
min-height: 500px;`;
CommBanner.defaultProps = {
    src: url,
    className: "img-fluid"
  };

function Home() {
  const { ref } = useParallax<HTMLDivElement>({ speed: 10 });
return (
  <Container ref={ref}>
    <NavBar />
    <Spacer />
    {/*<a href='/Comms'> <CommBanner /> </a>*/}
    <div id='about'><About /></div>
    <Divider header="Reel" anchor="reel" />
    <LiteYouTubeEmbed 
        id="uNaJa4hekMc"
        title="Tubbs Reel V3"
    />
    <Divider header="Gallery" anchor="gallery" />
      <BigImage />
      <PortfolioCarousel />
      <ImageLineup />
    <Row>
      <Col lg={6}>
        <SocialLinks />
      </Col>
      <Col lg={6}>
        <Image />
      </Col>
    </Row>
  </Container>
  );
} 
export default Home;
